import React from 'react'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import qs from 'querystringify'
import Modal from '../../shared/Modal'
import ModalClass from './shared/ModalClass'
import locationMarkerCircle from '../icons/location_marker_circle.svg'
import icons from '../helpers/icons'
import isMobile from '../helpers/isMobile'
import setGA from '../helpers/setGA'
import Close from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import TrackingIcon from '../icons/TrackingIcon'
import cloneDeep from 'lodash/cloneDeep'
import IconButton from '@material-ui/core/IconButton'
import REGIONS from '../helpers/regions'
import NavBar from './NavBar'
import IsbilenCenterByCar from '../icons/isbilen_car_location.svg'
import IsbilenCenterMarker from '../icons/isbilen_current_location.svg'
import CorrectLocationMarker from '../icons/correct_location_marker.js'
import TrackingMenu from './TrackingMenu'
import FeedbackModal from './FeedbackModal'
import SuccessModal from './SuccessModal'
import AutoMarker from '../icons/AutoMarker'
import logGoogleEvent from './logGoogleEvent'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { PublicMapboxMap } from '../../shared/MapboxMap.js'
import mapboxgl from 'mapbox-gl'
import { Marker } from 'react-map-gl'
import MarkerWithPopup from './shared/MarkerWithPopup.js'
import { createConsumer } from '@rails/actioncable'
import getTenantLocale from '../helpers/getTenantLocale'

class FraastFoodCustomerTrackingMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nearbyPoints: [],
      fetchGoogleETA: false,
      isMobile: isMobile(),
      expiredModalOpen: false,
      depo: {},
      menuOpen: false,
      editableFG: null,
      minutesRemaining: 0,
      unsubscribeModal: false,
      region: REGIONS[this.props.tenant],
      feedbackOpen: false,
      successOpen: false,
      customerSentFeedback: false,
      smsCheckbox: false,
      marketingCheckbox: false,
      currentLocation: null
    }
  }

  SEARCH_LOCATION_ZINDEX_OFFSET = 400
  ROUTE_POINT_ZINDEX_OFFSET = 200

  componentDidMount() {
    if (this.props.customer !== undefined) {
      this.showUnsubscribtionModal()
    } else {
      window.addEventListener('resize', () => {
        this.setState({ isMobile: isMobile() })
      })
      setGA()
      this.fetchData()
      logGoogleEvent('trackingViewLoaded')
      this.showFeedbackBar()
    }
  }

  showFeedbackBar() {
    if (this.props.customerHasFeedback || !this.props.customerPresent) {
      this.setState({ customerSentFeedback: true })
    }
  }

  activateAdditionalData() {
    this.subscribeToPositionUpdate()
    this.requestCurrentLocation()
    this.setState({ fetchGoogleETA: true }, () => this.fetchGoogleETAEveryMin())
    this.calculateBounds()
  }

  fetchData() {
    axios
      .get(
        Routes.public_plan_event_route_point_path({
          uuid: this.props.plan_event_route_point_uuid,
          locale: getTenantLocale(this.props.tenant),
          c: this.customerUuid(),
          format: 'json'
        })
      )
      .then(({ data }) => {
        if (!data) {
          this.setState({ expiredModalOpen: true })
          return
        }

        this.car = data.car
        this.setState(
          {
            carLocation: this.car.coordinates,
            originalCarLocation: this.car.coordinates,
            nearbyPoints: this.updatePointsExistingCarStopPresence(data.nearby_points),
            planEventRoutePoint: {
              id: data.id,
              address: data.address,
              coordinates: data.coordinates,
              original_google_estimate_time: data.original_google_estimate_time,
              position: data.position,
              point_visited: data.point_visited,
              google_estimate_time: data.google_estimate_time
            },
            depo: data.depo
          },
          this.activateAdditionalData.bind(this)
        )
      })
  }

  fetchGoogleETAEveryMin() {
    if (this.state.fetchGoogleETA) {
      setInterval(async () => {
        try {
          const { data } = await axios.get(
            Routes.public_plan_event_route_point_path({
              uuid: this.props.plan_event_route_point_uuid,
              locale: getTenantLocale(this.props.tenant),
              format: 'json'
            })
          )

          let nearbyPoints = cloneDeep(data.nearby_points)
          nearbyPoints = this.updatePointsExistingCarStopPresence(nearbyPoints)
          this.setState({
            nearbyPoints,
            planEventRoutePoint: {
              id: data.id,
              address: data.address,
              coordinates: data.coordinates,
              original_google_estimate_time: data.original_google_estimate_time,
              position: data.position,
              point_visited: data.point_visited,
              google_estimate_time: data.google_estimate_time
            }
          })
        } catch (e) {
          console.log(e)
        }
      }, 60000)
    }
  }

  updatePointsExistingCarStopPresence(points) {
    if (points.filter((p) => p.point_visited).length > 0) {
      let highestIndex = 0
      points.forEach((p, i) => {
        p.point_visited ? (highestIndex = i) : (highestIndex = highestIndex)
      })

      for (let i = 0; i <= highestIndex; i++) {
        points[i].point_visited = true
      }
    }
    return points
  }

  subscribeToPositionUpdate() {
    this.carPositionUpdateInterval = setInterval(this.requestCarPositionUpdate.bind(this), 5000)
    this.subscription = createConsumer('/cable').subscriptions.create(
      {
        channel: 'CarLocationChannel',
        car_id: this.car.id,
        tenant: this.props.tenant
      },
      { received: this.onCarLocationUpdate.bind(this) }
    )
  }

  componentWillUnmount() {
    clearInterval(this.carPositionUpdateInterval)
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  requestCarPositionUpdate() {
    const locale = window.location.pathname.split('/')[1]
    axios.get(Routes.public_perform_car_latest_location_update_path(locale, this.car.id))
  }

  requestCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentLocation: {
            lng: position.coords.longitude,
            lat: position.coords.latitude
          }
        })
      })
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  onCarLocationUpdate(coordinates) {
    this.setState({ carLocation: coordinates })
  }

  toggleSettingsMenu() {
    this.setState({ settingsMenuVisible: !this.state.settingsMenuVisible })
  }

  toggleExpiredModal() {
    this.setState({ expiredModalOpen: !this.state.expiredModalOpen })
  }

  showUnsubscribtionModal() {
    this.setState({ unsubscribeModal: true })
  }

  showFeedbackModal = () => {
    this.setState({ feedbackOpen: true })
    logGoogleEvent('feedbackButtonClicked')
  }

  closeFeedbackModal = () => this.setState({ feedbackOpen: false })
  showSuccessPage = () =>
    this.setState({
      feedbackOpen: false,
      successOpen: true,
      customerSentFeedback: true
    })
  closeSuccessPage = () => this.setState({ successOpen: false })

  showMenu = () => this.setState({ menuOpen: true })
  closeMenu = () => this.setState({ menuOpen: false })

  hideUnsubscribtionModal() {
    /* @customer is defined in unsubscribtion view
       @customerPresence is defined in show view */
    if (this.props.customer !== undefined) {
      window.location = this.props.translations.homepage_href
    } else {
      this.setState({ unsubscribeModal: false })
    }
  }

  allRoutePoints() {
    const { nearbyPoints, planEventRoutePoint } = this.state
    const routePoints = nearbyPoints.concat(planEventRoutePoint)

    return sortBy(
      routePoints.filter((rp) => !!rp),
      'position'
    )
  }

  renderPlanEventRoutePoint() {
    const { translations } = this.props
    if (!this.state.planEventRoutePoint) return

    const { id, coordinates, point_visited } = this.state.planEventRoutePoint
    let icon

    if (point_visited) {
      return (
        <Marker key={`${id}-${point_visited}`} longitude={coordinates.lng} latitude={coordinates.lat}>
          <TrackingIcon visited={true} />
        </Marker>
      )
    } else {
      return (
        <Marker key={`${id}-${point_visited}`} longitude={coordinates.lng} latitude={coordinates.lat}>
          <div>
            <CorrectLocationMarker />
          </div>
        </Marker>
      )
    }
  }

  renderCar() {
    if (!this.state.carLocation) return

    return (
      <Marker longitude={this.state.carLocation.lng} latitude={this.state.carLocation.lat}>
        <AutoMarker />
      </Marker>
    )
  }

  renderPlanEventRoutePoints() {
    const { planEventRoutePoint, nearbyPoints } = this.state

    if (planEventRoutePoint) {
      return nearbyPoints.map((routePoint, index) => {
        const { id, coordinates, point_visited, google_estimate_time } = routePoint

        if (id === planEventRoutePoint.id) return
        const anchor = point_visited ? [18, 30] : [20, 50]
        return (
          <MarkerWithPopup
            className={'map-zoom-animated'}
            key={`${id}-${point_visited}-${google_estimate_time}`}
            coordinates={coordinates}
            point_visited={point_visited}
            logGoogleEvent={logGoogleEvent}
            routePoint={routePoint}
          />
        )
      })
    }
  }

  reCenterMapByCarLocation() {
    const { carLocation } = this.state
    logGoogleEvent('trackingCarIconClicked')
    this.map.flyTo({ center: carLocation, zoom: 15 })
  }

  reCenterMapByCurrentLocation() {
    const {
      planEventRoutePoint: { coordinates }
    } = this.state
    logGoogleEvent('trackingPointIconClicked')
    this.map.flyTo({ center: coordinates, zoom: 15 })
  }

  renderCurrentLocation() {
    if (!this.state.currentLocation) return
    return (
      <Marker
        key='currentLocation'
        style={{ offset: '30px' }}
        longitude={this.state.currentLocation.lng}
        latitude={this.state.currentLocation.lat}
        anchor='center'
      >
        <div className='location-marker-wrapper'>
          <img className='location-with-animated-pulse' src={locationMarkerCircle} />
        </div>
      </Marker>
    )
  }

  renderButton(title, className, action, disabled = false) {
    return (
      <button onClick={action} className={className} disabled={disabled}>
        {title}
      </button>
    )
  }

  expiredModalContent() {
    const { translations } = this.props
    return {
      headerIcon: icons[this.props.tenant].logo,
      logo: icons[this.props.tenant].iceCreamModal,
      headerTitle: translations.expired_title,
      largeParagraph: translations.expired_message,
      smallParagraph: isHjemis() ? <a href={translations.homepage_href}>{translations.href_message}</a> : '',
      actionButton: this.renderButton(
        'OK',
        'customer-subscribe-button black-button',
        this.toggleExpiredModal.bind(this)
      )
    }
  }

  renderModal() {
    const { expiredModalOpen } = this.state
    const { translations } = this.props
    let content = false

    if (expiredModalOpen) content = this.expiredModalContent()
    // else if (subscriptionModalOpen) content = this.subscribtionModalContent()

    if (content) {
      return (
        <ModalClass
          open={true}
          close={this.toggleExpiredModal.bind(this)}
          BackdropProps={{ classes: { root: 'modal-backdrop' } }}
          modalContainer={{ height: 'unset', top: 50 }}
          translations={translations}
          content={content}
        ></ModalClass>
      )
    }
  }

  calculateBounds() {
    const routePoints = this.allRoutePoints()
    const { originalCarLocation } = this.state

    if (routePoints.length > 0) {
      const mappedPoints = routePoints.map((rp) => rp.coordinates)
      mappedPoints.push(originalCarLocation)

      const bounds = new mapboxgl.LngLatBounds(mappedPoints[0], mappedPoints[0])
      mappedPoints.forEach((coord) => {
        bounds.extend(coord)
      })

      if (this.map) {
        this.map.fitBounds(bounds, { padding: 150 })
      }
    }
  }

  handleRender(map, e) {
    this.map = map.target
  }

  renderMapContainer() {
    const { isMobile, planEventRoutePoint, region } = this.state
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    const android = !!navigator.platform && /Android|Linux/.test(navigator.platform)

    let correctClassName
    if (iOS && !!planEventRoutePoint) {
      correctClassName = 'ios-map'
    } else if (android && !!planEventRoutePoint) {
      correctClassName = 'android-map'
    } else {
      correctClassName = (!planEventRoutePoint && 'full-map') || ''
    }

    return (
      <div className='map-container tracking-map'>
        <PublicMapboxMap
          className={correctClassName}
          onRender={this.handleRender.bind(this)}
          initialViewState={{
            longitude: region.coordinates.lng,
            latitude: region.coordinates.lat,
            zoom: region.zoom
          }}
          maxZoom={18}
          minZoom={7}
          accessToken={this.props.access_token}
        >
          {this.renderCurrentLocation()}
          {this.renderPlanEventRoutePoints()}
          {this.renderPlanEventRoutePoint()}
          {this.renderCar()}
        </PublicMapboxMap>
      </div>
    )
  }

  renderCarMapIcon() {
    const { planEventRoutePoint, isMobile, customerSentFeedback } = this.state
    if (planEventRoutePoint) {
      const desktopAdjust = isMobile ? 0 : 85
      const iconSrc = IsbilenCenterByCar
      const feedbackAdjust = customerSentFeedback ? 0 : 70
      return (
        <div
          style={{ bottom: 100 + desktopAdjust + feedbackAdjust }}
          onClick={this.reCenterMapByCarLocation.bind(this)}
          className='map-action-button'
        >
          <img src={iconSrc} />
        </div>
      )
    }
  }

  renderCurrentLocationMapIcon() {
    const { planEventRoutePoint, isMobile, customerSentFeedback } = this.state
    if (planEventRoutePoint) {
      const desktopAdjust = isMobile ? 0 : 85
      const iconSrc = IsbilenCenterMarker
      const feedbackAdjust = customerSentFeedback ? 0 : 70
      return (
        <div
          style={{ bottom: 40 + desktopAdjust + feedbackAdjust }}
          onClick={this.reCenterMapByCurrentLocation.bind(this)}
          className='map-action-button'
        >
          <img src={iconSrc} />
        </div>
      )
    }
  }

  customerUuid() {
    return (this.props.customer && this.props.customer.uuid) || qs.parse(window.location.search).c
  }

  handleUnsubscribtion() {
    logGoogleEvent('trackingCustomerHasUnsubscribed')
    axios
      .delete(
        Routes.public_customer_path(this.customerUuid(), {
          locale: getTenantLocale(this.props.tenant),
          format: 'json'
        })
      )
      .then((res) => {
        window.location = "https://frastfoods.com/"
      })
      .catch((err) => {
        window.location = "https://frastfoods.com/"
      })
  }

  handlePartialUnsubscription() {
    if (this.state.smsCheckbox && this.state.marketingCheckbox) {
      this.handleUnsubscribtion()
    } else {
      axios
        .post(
          Routes.partial_unsubscribe_public_customer_path({
            uuid: this.customerUuid(),
            locale: getTenantLocale(this.props.tenant),
            format: 'json'
          }),
          {
            sms: this.state.smsCheckbox,
            marketing: this.state.marketingCheckbox
          }
        )
        .then((res) => {
          window.location = "https://frastfoods.com/"
        })
        .catch((err) => {
          window.window.location = "https://frastfoods.com/"
        })
    }
  }

  renderCloseIcon() {
    return (
      <IconButton className='modal-close-button' onClick={this.hideUnsubscribtionModal.bind(this)}>
        <Close />
      </IconButton>
    )
  }

  renderUnsubscribeButton() {
    const { unsubscribe } = this.props.translations
    return (
      <button
        onClick={this.handleUnsubscribtion.bind(this)}
        className='customer-subscribe-button success-button black-button'
      >
        {unsubscribe}
      </button>
    )
  }

  renderPartialUnsubscribeButton() {
    const { unsubscribe } = this.props.translations
    return (
      <button
        onClick={this.handlePartialUnsubscription.bind(this)}
        className='customer-subscribe-button success-button black-button'
      >
        {unsubscribe}
      </button>
    )
  }

  renderAlreadyUnsubscribedMessage() {
    const { already_unsubscribed } = this.props.translations
    return <h5>{already_unsubscribed}</h5>
  }

  renderUnsubscriptionContent() {
    const { do_you_wish_to_unsubscribe } = this.props.translations
    return (
      <div className='modal-container unsubscribe-modal'>
        {this.renderCloseIcon()}
        <h4>{do_you_wish_to_unsubscribe}</h4>
        <img
          className='logo'
          src={isHjemis() ? icons[this.props.tenant].carPhoneLogo : icons[this.props.tenant].logo}
        />
        {this.props.customerPresent || this.props.customer
          ? this.renderUnsubscribeButton()
          : this.renderAlreadyUnsubscribedMessage()}
      </div>
    )
  }

  renderPartialUnsubscriptionContent() {
    const { do_you_wish_to_unsubscribe, partial_unsubscribe } = this.props.translations
    return (
      <>
        <div className='modal-container unsubscribe-modal'>
          {this.renderCloseIcon()}
          <h4>{partial_unsubscribe}</h4>
          <img
            style={{ height: 130, width: 'auto' }}
            className='logo'
            src={isHjemis() ? icons[this.props.tenant].carPhoneLogo : icons[this.props.tenant].logo}
          />
          <FormControlLabel
            label='Afmeld SMS med ruteinformation'
            control={
              <Checkbox
                className='check-order'
                checked={this.state.smsCheckbox}
                onChange={this.handleSmsCheckBox.bind(this)}
              />
            }
          />
          <FormControlLabel
            label='Afmeld SMS med markedsføring'
            control={
              <Checkbox
                className='check-order'
                checked={this.state.marketingCheckbox}
                onChange={this.handleMarketingCheckBox.bind(this)}
              />
            }
          />
          {this.props.customerPresent || this.props.customer
            ? this.renderPartialUnsubscribeButton()
            : this.renderAlreadyUnsubscribedMessage()}
        </div>
      </>
    )
  }

  handleSmsCheckBox(e) {
    this.setState({ smsCheckbox: e.target.checked })
  }

  handleMarketingCheckBox(e) {
    this.setState({ marketingCheckbox: e.target.checked })
  }

  renderUnsubscribeModal() {
    let content = this.renderUnsubscriptionContent()
    if (this.props.customer && this.props.customer.sms_permission && this.props.customer.marketing_agreement) {
      content = this.renderPartialUnsubscriptionContent()
    }

    return (
      <Modal
        open={this.state.unsubscribeModal}
        close={this.hideUnsubscribtionModal.bind(this)}
        classes='modal-block'
        style={{ height: 'fit-content' }}
        BackdropProps={{ classes: { root: 'modal-backdrop' } }}
      >
        {content}
      </Modal>
    )
  }

  renderMenu() {
    return (
      <Slide direction='down' in={this.state.menuOpen} mountOnEnter unmountOnExit>
        <TrackingMenu
          translations={this.props.translations}
          customerUuid={this.customerUuid()}
          openUnsubscribe={this.showUnsubscribtionModal.bind(this)}
          closeMenu={this.closeMenu}
        />
      </Slide>
    )
  }

  renderFeedbackBar() {
    const {
      translations: { rate_your_experience }
    } = this.props

    if (!this.state.customerSentFeedback) {
      return (
        <div className='feedback-bottom-container'>
          <div className='feedback-button' onClick={this.showFeedbackModal}>
            {rate_your_experience}
          </div>
        </div>
      )
    }
  }

  renderFeedbackForm() {
    return (
      <Slide direction='up' in={this.state.feedbackOpen} mountOnEnter unmountOnExit>
        <FeedbackModal
          showSuccessPage={this.showSuccessPage}
          customerUuid={this.customerUuid()}
          planEventRoutePoint={this.state.planEventRoutePoint}
          translations={this.props.translations}
          closeFeedbackModal={this.closeFeedbackModal}
          tenant={this.props.tenant}
        />
      </Slide>
    )
  }

  renderSuccessPage() {
    return (
      <Slide direction='left' in={this.state.successOpen} mountOnEnter unmountOnExit>
        <SuccessModal translations={this.props.translations} closeSuccessPage={this.closeSuccessPage} />
      </Slide>
    )
  }

  render() {
    return (
      <div className={`${this.props.tenant} customers main-container`}>
        {this.renderMenu()}
        <div className='map-view-container tracking'>
          <NavBar
            tenant={this.props.tenant}
            showMenu={this.showMenu}
            translations={this.props.translations}
            planEventRoutePoint={this.state.planEventRoutePoint}
          />
          {this.renderMapContainer()}
        </div>
        {this.renderCurrentLocationMapIcon()}
        {this.renderCarMapIcon()}
        {this.renderModal()}
        {this.renderUnsubscribeModal()}
        {this.renderFeedbackBar()}
        {this.renderFeedbackForm()}
        {this.renderSuccessPage()}
      </div>
    )
  }
}

export default FraastFoodCustomerTrackingMap
