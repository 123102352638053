import React from 'react'
import { Provider } from 'react-redux'
import store from '../../files/store'
import RouteMap from './RouteMap'
import Paper from '@material-ui/core/Paper'
import RoutesComponent from './RoutesComponent'
import ErrorCatcher from '../shared/ErrorCatcher'

const MapWithStore = (props) => {
  return (
    <Provider store={store}>
      <RouteMap {...props}>
        <Paper className='map-sidebar'>
          <ErrorCatcher>
            <RoutesComponent accessToken={props.access_token} isbilenAccessToken={props.isbilen_access_token} />
          </ErrorCatcher>
        </Paper>
      </RouteMap>
    </Provider>
  )
}

export default MapWithStore
