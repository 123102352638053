import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map, { NavigationControl } from 'react-map-gl'
import mapboxglSupported from '@mapbox/mapbox-gl-supported'

const env = document.querySelector('meta[name=env]').getAttribute('content')
const STREET_STYLE = env == 'test' ? 'mapbox://styles/foodunion/cjxoepeai187z1cmmyt148do9' : 'mapbox://styles/mapbox/streets-v12'
const SATALITE_STYLE = env == 'test' ? 'mapbox://styles/foodunion/cjxoepeai187z1cmmyt148do9' : 'mapbox://styles/mapbox/satellite-streets-v12'

class PublicMapboxMap extends React.Component {
  state = {
    mapStyle: 'mapbox://styles/foodunion/cjxoepeai187z1cmmyt148do9'
  }

  render() {
    if (mapboxglSupported.supported()) {
      return (
        <Map
          initialViewState={this.props.initialViewState}
          style={{ width: '100%', height: this.props.height || '100%' }}
          mapStyle={this.state.mapStyle}
          mapboxAccessToken={this.props.accessToken}
          {...this.props}
        >
          {this.props.children}
          <NavigationControl position={this.props.navPosition || 'bottom-right'} />
        </Map>
      )
    } else {
      return (
        <div>
          WebGL not enabled
        </div>
      )
    }
  }
}

class MapboxMap extends React.Component {
  state = {
    mapStyle: STREET_STYLE
  }

  toggleStyle = () => {
    this.setState((prevState) => (
      {
        mapStyle: prevState.mapStyle == STREET_STYLE ? SATALITE_STYLE : STREET_STYLE,
      }
    ))
  }

  render() {
    if (mapboxglSupported.supported()) {
      return (
        <Map
          initialViewState={this.props.initialViewState}
          style={{ width: '100%', height: this.props.height || '100%' }}
          mapStyle={this.state.mapStyle}
          mapboxAccessToken={this.props.accessToken}
          {...this.props}
        >
          <div className='map-action-container map-toggle-style'>
            <a className='map-layer-label' onClick={this.toggleStyle}>
              <i className="material-icons">layers</i>
            </a>
          </div>
          {this.props.children}
          <NavigationControl position={this.props.navPosition || 'bottom-right'} />
        </Map>
      )
    } else {
      return (
        <div>
          WebGL not enabled
        </div>
      )
    }
  }
}

MapboxMap.defaultProps = {
  accessToken: 'ACCESS TOKEN'
}

export { PublicMapboxMap }
export default MapboxMap
