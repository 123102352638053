import React from 'react'
import { connect } from 'react-redux'
import FunTextField from '../forms/FunTextField'
import Route from '../../models/Route'
import MainTitle from '../shared/MainTitle'
import axios from 'axios'
import SaveButton from '../shared/SaveButton'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import CancelButton from '../shared/CancelButton'
import { setFlash, setMainTitle } from '../../files/actions/index'
import extractDataErrors from '../support/extractDataErrors'
import find from 'lodash/find'
import moment from 'moment'
import FunTimeInput from '../forms/FunTimeInput'

class RouteFormClass extends React.Component {
  state = { ...Route(), dialogOpen: false, updated: false }

  componentDidMount() {
    const route = this.findRouteInStore()
    if (route) {
      this.setRouteAndMainTitle(route)
    } else if (this.routeId()) {
      axios
        .get(this.loadUrl())
        .then((res) => {
          this.setRouteAndMainTitle(res.data)
        })
        .catch((error) => {
          this.props.setFlash(`${error} (${this.loadUrl()})`)
        })
    } else {
      this.setMainTitle()
    }
  }

  setMainTitle() {
    this.props.setMainTitle(!!this.state.id ? `Edit Route ${this.state.name}` : 'New route')
  }

  setRouteAndMainTitle(route) {
    this.setState({ ...route }, () => this.setMainTitle())
  }

  loadUrl() {
    return Routes.route_path(window.tenant, window.depoId, this.routeId(), {
      format: 'json'
    })
  }

  url() {
    if (this.state.id) {
      return Routes.route_path(window.tenant, window.depoId, this.routeId())
    }
    return Routes.routes_path(window.tenant, window.depoId)
  }

  data() {
    const time = moment(this.state.route_duration, 'HH:mm')
    const decimalTime = time.hour() + time.minutes() / 60
    return {
      format: 'json',
      route: {
        name: this.state.name,
        radius: this.state.radius,
        tag: this.state.tag,
        route_duration: decimalTime,
        administration_time: this.state.administration_time,
        eod_administration_time: this.state.eod_administration_time,
        minimum_efficient_sales_count: this.state.minimum_efficient_sales_count
      }
    }
  }

  routeId() {
    return this.props.match.params.routeId
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  findRouteInStore() {
    return find(this.props.routes, ({ id }) => {
      return id == this.routeId()
    })
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value, updated: true })
  }

  handleTimeChange(field, value) {
    this.setState({ [field]: value })
  }

  handleSubmit(e) {
    e.preventDefault()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then((res) => {
        this.setState({ id: res.data.id, errors: {} })
        this.props.history.push(window.Routes.routes_path(window.tenant, window.depoId))
      })
      .catch((error) => {
        this.handleSubmitError(error)
      })
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen })
  }

  toggleDialogIfUpdated(e) {
    if (this.state.updated) {
      e.preventDefault()
      this.toggleDialog()
    }
  }

  closeDialogAndLeave() {
    this.toggleDialog()
    this.props.history.goBack()
  }

  renderApprovedByHeading() {
    if (!this.state.approval_user_and_time) return
    return (
      <div className='mb-1 text-center'>
        <span className='mdl-chip w-100' style={{ boxSizing: 'border-box' }}>
          <span className='mdl-chip__text'>{this.state.approval_user_and_time}</span>
        </span>
      </div>
    )
  }

  renderAdministrationTimeInput() {
    const settings = {
      value: this.state.administration_time,
      onChange: this.handleTimeChange.bind(this, 'administration_time'),
      helperText: 'Time which will be added to each point individually, like stop time'
    }

    return (
      <FunTimeInput
        id='administration_time'
        label='Administration time'
        settings={settings}
        containerStyles={{ width: '100%', marginBottom: 15 }}
      />
    )
  }

  renderEodAdministrationTimeInput() {
    const settings = {
      value: this.state.eod_administration_time,
      onChange: this.handleTimeChange.bind(this, 'eod_administration_time'),
      helperText: 'Time which will be added at the end of route for EOD administration'
    }

    return (
      <FunTimeInput
        id='eod_administration_time'
        label='EOD Administration time'
        settings={settings}
        containerStyles={{ width: '100%', marginBottom: 15 }}
      />
    )
  }

  renderDepoTimes() {
    const fromSettings = {
      value: this.state.time_from_depo,
      onChange: this.handleTimeChange.bind(this),
      disabled: true,
      helperText: 'Calculated from depo to first point'
    }
    const toSettings = {
      value: this.state.time_to_depo,
      onChange: this.handleTimeChange.bind(this),
      disabled: true,
      helperText: 'Calculated to depo from last point'
    }

    return (
      <React.Fragment>
        <FunTimeInput
          id='time_from_depo'
          label='Time from depo'
          settings={fromSettings}
          containerStyles={{ width: '100%', marginBottom: 15 }}
        />
        <FunTimeInput
          id='time_to_depo'
          label='Time to depo'
          settings={toSettings}
          containerStyles={{ width: '100%', marginBottom: 15 }}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className='content-container'>
        <MainTitle />
        {this.renderApprovedByHeading()}
        <form noValidate autoComplete='off' onSubmit={this.handleSubmit.bind(this)}>
          <FunTextField field='name' state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField
            field='tag'
            state={this.state}
            handleChange={this.handleChange.bind(this)}
            helperText='Leave this empty in order have route in active state, tagging route will move it under tagged routes'
          />
          <FunTextField field='radius' state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField
            type='time'
            field='route_duration'
            state={this.state}
            handleChange={this.handleChange.bind(this)}
          />
          <FunTextField
            type='number'
            inputProps={{ min: '1', step: '1' }}
            field='minimum_efficient_sales_count'
            state={this.state}
            handleChange={this.handleChange.bind(this)}
          />
          {this.renderAdministrationTimeInput()}
          {this.renderEodAdministrationTimeInput()}
          {this.renderDepoTimes()}
          <SaveButton />
          <CancelButton
            to={Routes.routes_path(window.tenant, window.depoId)}
            onClick={this.toggleDialogIfUpdated.bind(this)}
          />
        </form>
        <ConfirmationDialog
          open={this.state.dialogOpen}
          close={this.toggleDialog.bind(this)}
          leave={this.closeDialogAndLeave.bind(this)}
          confirmAndCloseText='Leave'
          cancelText='Cancel'
          title='Unsaved changes detected!'
          content='There are unsaved changes which will be lost if you leave!'
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash))
  }
}

const RouteForm = connect(mapStateToProps, mapDispatchToProps)(RouteFormClass)

export default RouteForm
