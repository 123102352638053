import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import RouteForm from './RouteForm'
import Routes from './Routes'
import RoutePointsTable from '../routePoints/RoutePointsTable'

const RoutesComponent = (componentProps) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/depo/:depoId/routes/:routeId/edit' component={RouteForm} />
        <Route path='/:tenant/depo/:depoId/routes/new' component={RouteForm} />
        <Route exact path='/:tenant/depo/:depoId/routes' component={Routes} />
        <Route path='/:tenant/depo/:depoId/routes/filter/:filter' component={Routes} />
        <Route
          path='/:tenant/depo/:depoId/route/:routeId/route_points'
          render={(props) => (
            <RoutePointsTable
              {...props}
              accessToken={componentProps.accessToken}
              isbilenAccessToken={componentProps.isbilenAccessToken}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default RoutesComponent
