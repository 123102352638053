import WebpackerReact from 'webpacker-react'

import AdminUsersIndexWithStore from 'components/admin/users/AdminUsersIndexWithStore'
import AdminCarsIndexWithStore from 'components/admin/cars/AdminCarsIndexWithStore'
import AdminDeposIndexWithStore from 'components/admin/depos/AdminDeposIndexWithStore'
import AdminCustomersIndexWithStore from 'components/admin/customers/AdminCustomersIndexWithStore'
import AdminSmsCampaignsIndexWithStore from 'components/admin/smsCampaigns/AdminSmsCampaignsIndexWithStore'
import UserHttpRequestsIndexWithStore from 'components/admin/userHttpRequests/UserHttpRequestsIndexWithStore'
import MapWithStore from 'components/map/MapWithStore'
import PlanEventTemplatesIndexWithStore from 'components/planEventTemplates/PlanEventTemplatesIndexWithStore'
import BrowserCheck from 'components/shared/BrowserCheck'
import SupportPlanEventRoutePointsMap from 'components/public/support/SupportPlanEventRoutePointsMap'
import EndOfDayreportsIndexWithStore from 'components/endOfDayReports/EndOfDayReportsIndexWithStore'
import AdminStopTypesIndexWithStore from 'components/admin/stopTypes/AdminStopTypesIndexWithStore'
import AdminProductsIndexWithStore from 'components/inventory/products/AdminProductsIndexWithStore'
import WarehousesIndexWithStore from 'components/inventory/warehouses/WarehousesIndexWithStore'
import WarehouseOrdersIndex from 'components/inventory/warehouseOrders/WarehouseOrdersIndex'
import EshopOrdersIndex from 'components/eshop/orders'
import AdminSmsTemplatesIndex from 'components/admin/smsTemplates/AdminSmsTemplatesIndex'
import FeedbackIndex from 'components/feedbacks/FeedbackIndex'
import AdminWallmobIndex from 'components/admin/wallmob/AdminWallmobIndex'
import AdminB2BCustomersIndex from 'components/admin/b2bCustomers/AdminB2BCustomersIndex'
import EshopB2bOrdersIndex from 'components/eshop/b2b_orders'
import AdminB2bOrdersIndex from 'components/admin/b2bOrders/index'
import AppWrapper from 'components/shared/AppWrapper'
import './global_variables'

WebpackerReact.setup({
  AdminUsersIndexWithStore,
  AdminCarsIndexWithStore,
  AdminDeposIndexWithStore,
  AdminCustomersIndexWithStore,
  AdminSmsCampaignsIndexWithStore,
  MapWithStore,
  PlanEventTemplatesIndexWithStore,
  BrowserCheck,
  UserHttpRequestsIndexWithStore,
  SupportPlanEventRoutePointsMap,
  EndOfDayreportsIndexWithStore,
  AdminStopTypesIndexWithStore,
  AdminProductsIndexWithStore,
  WarehousesIndexWithStore,
  WarehouseOrdersIndex,
  EshopOrdersIndex,
  AdminSmsTemplatesIndex,
  FeedbackIndex,
  AdminWallmobIndex,
  AdminB2BCustomersIndex,
  EshopB2bOrdersIndex,
  AdminB2bOrdersIndex,
  AppWrapper
})
