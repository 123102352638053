import React from 'react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import AdminUsersIndexWithStore from '../admin/users/AdminUsersIndexWithStore'
import AdminCarsIndexWithStore from '../admin/cars/AdminCarsIndexWithStore'
import AdminDeposIndexWithStore from '../admin/depos/AdminDeposIndexWithStore'
import AdminCustomersIndexWithStore from '../admin/customers/AdminCustomersIndexWithStore'
import AdminSmsCampaignsIndexWithStore from '../admin/smsCampaigns/AdminSmsCampaignsIndexWithStore'
import UserHttpRequestsIndexWithStore from '../admin/userHttpRequests/UserHttpRequestsIndexWithStore'
import MapWithStore from '../map/MapWithStore'
import PlanEventTemplatesIndexWithStore from '../planEventTemplates/PlanEventTemplatesIndexWithStore'
import BrowserCheck from '../shared/BrowserCheck'
import SupportPlanEventRoutePointsMap from '../public/support/SupportPlanEventRoutePointsMap'
import EndOfDayreportsIndexWithStore from '../endOfDayReports/EndOfDayReportsIndexWithStore'
import AdminStopTypesIndexWithStore from '../admin/stopTypes/AdminStopTypesIndexWithStore'
import AdminProductsIndexWithStore from '../inventory/products/AdminProductsIndexWithStore'
import WarehousesIndexWithStore from '../inventory/warehouses/WarehousesIndexWithStore'
import WarehouseOrdersIndex from '../inventory/warehouseOrders/WarehouseOrdersIndex'
import EshopOrdersIndex from '../eshop/orders'
import AdminSmsTemplatesIndex from '../admin/smsTemplates/AdminSmsTemplatesIndex'
import FeedbackIndex from '../feedbacks/FeedbackIndex'
import AdminWallmobIndex from '../admin/wallmob/AdminWallmobIndex'
import AdminB2BCustomersIndex from '../admin/b2bCustomers/AdminB2BCustomersIndex'
import EshopB2bOrdersIndex from '../eshop/b2b_orders'
import AdminB2bOrdersIndex from '../admin/b2bOrders/index'
import FraastFoodSubscribeMap from '../public/customers/FraastFoodSubscribeMap'
import FraastFoodCustomerTrackingMap from '../public/customers/FraastFoodCustomerTrackingMap'

const Components = {
  adminusersindexwithstore: AdminUsersIndexWithStore,
  admincarsindexwithstore: AdminCarsIndexWithStore,
  admindeposindexwithstore: AdminDeposIndexWithStore,
  admincustomersindexwithstore: AdminCustomersIndexWithStore,
  adminsmscampaignsindexwithstore: AdminSmsCampaignsIndexWithStore,
  userhttprequestsindexwithstore: UserHttpRequestsIndexWithStore,
  mapwithstore: MapWithStore,
  planeventtemplatesindexwithstore: PlanEventTemplatesIndexWithStore,
  browsercheck: BrowserCheck,
  supportplaneventroutepointsmap: SupportPlanEventRoutePointsMap,
  endofdayreportsindexwithstore: EndOfDayreportsIndexWithStore,
  adminstoptypesindexwithstore: AdminStopTypesIndexWithStore,
  adminproductsindexwithstore: AdminProductsIndexWithStore,
  warehousesindexwithstore: WarehousesIndexWithStore,
  warehouseordersindex: WarehouseOrdersIndex,
  eshopordersindex: EshopOrdersIndex,
  adminsmstemplatesindex: AdminSmsTemplatesIndex,
  feedbackindex: FeedbackIndex,
  adminwallmobindex: AdminWallmobIndex,
  adminb2bcustomersindex: AdminB2BCustomersIndex,
  eshopb2bordersindex: EshopB2bOrdersIndex,
  adminb2bordersindex: AdminB2bOrdersIndex,
  fraastfoodsubscribemap: FraastFoodSubscribeMap,
  fraastfoodcustomertrackingmap: FraastFoodCustomerTrackingMap,
}

const theme = createTheme({
  overrides: {
    MuiTableHead: {
      root: {
        height: 56,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 2,
        minHeight: 32,
        minWidth: 64,
      },
    },
    MuiTableRow: {
      root: {
        height: 40
      }
    }
  }
})

const AppWrapper = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      {React.createElement(
        Components[props.entrypoint.toLowerCase()],
        props.params ? props.params : {}
      )}
    </MuiThemeProvider>
  )
}

export default AppWrapper
