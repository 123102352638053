import React, { useState } from 'react'
import PlanEventPopup from './PlanEventPopup'
import PlanEventPointIcon from '../shared/PlanEventPointIcon'
import { Marker, Popup } from 'react-map-gl'

export default function PlanEventMarkerWithPopup(props) {
  const [showPopup, setShowPopup] = useState(false)
  const { index, position, geoJsonPoint, routePoint, setFlash, planEvent, map } = props

  return (
    <div key={index}>
      <Marker
        longitude={position[1]}
        latitude={position[0]}
        anchor='bottom'
        color={geoJsonPoint.color}
        onClick={() => {
          setShowPopup(true)
          map.flyTo({
            center: [position[1], position[0]],
            offset: [0, 200],
            zoom: 15
          })
        }}
      >
        <PlanEventPointIcon
          revenue={routePoint.total_revenue}
          index={index}
          color={geoJsonPoint.color}
          width={'52'}
          height={'50'}
        />
      </Marker>
      {showPopup && (
        <Popup
          class
          longitude={props.position[1]}
          latitude={props.position[0]}
          anchor='bottom'
          offset={40}
          onClose={() => setShowPopup(false)}
        >
          <div className='mapbox-popup-content'>
            <PlanEventPopup
              key={geoJsonPoint.properties}
              planEvent={planEvent}
              routePoint={routePoint}
              index={geoJsonPoint.properties}
              color={geoJsonPoint.color}
              setFlash={setFlash}
            />
          </div>
        </Popup>
      )}
    </div>
  )
}
