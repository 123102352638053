import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Flash from '../shared/Flash'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  startLoading,
  stopLoading,
  createRoutePointFromMap,
  updatePoint,
  loadCustomers
} from '../../files/actions/index'
import RoutePoint from '../../models/RoutePoint'
import PrintMapWithGrid from './PrintMapWithGrid'
import map from 'lodash/map'
import Button from '@material-ui/core/Button'

import ConfirmationDialog from '../shared/ConfirmationDialog'
import Modal from '../shared/Modal'
import SimilarRoutesSelect from '../map/SimilarRoutesSelect.js'
import mapboxgl from 'mapbox-gl'

import REGIONS from '../public/helpers/regions'
import MapboxMap from '../shared/MapboxMap'
import { DrawControl } from './DrawControl'

import RouteMapCustomers from './shared/RouteMapCustomers'
import RouteMapMarkers from './shared/RouteMapMarkers'
import RouteMapPoints from './shared/RouteMapPoints'
import RouteMapUnplannedSales from './shared/RouteMapUnplannedSales'
import PointMovingConfirmationModal from './shared/PointMovingConfirmationModal'
import RouteMapTooltip from './shared/RouteMapTooltip'
import RouteMapMoveCustomersModal from './shared/RouteMapMoveCustomersModal'

const styles = {
  editLocationButton: {
    position: 'absolute',
    zIndex: 999,
    marginLeft: 13,
    top: 100,
    padding: 10
  },
  toggleMapMarkerButton: {
    position: 'absolute',
    zIndex: 999,
    marginLeft: 13,
    top: 150,
    padding: 10
  }
}

class RouteMapClass extends React.Component {
  state = {
    zoomedPoint: {},
    printPreview: null,
    mapPrinting: null,
    draggable: false,
    toggleTooltip: false,
    routeMinimumSalesEfficientCount: null,
    pointToMove: {},
    modalOpen: false,
    customerView: false,
    dialogOpen: false,
    currentPoint: {},
    marker: {},
    point: {},
    customers: [],
    phoneModalOpen: false,
    useNewMapMarkers: false,
    initialView: {
      longitude: REGIONS[tenant].coordinates.lng,
      latitude: REGIONS[tenant].coordinates.lat,
      zoom: REGIONS[tenant].zoom
    }
  }

  componentDidMount() {
    axios.get(Routes.admin_depo_path(window.tenant, window.depoId, { format: 'json' })).then((res) => {
      const depoCoordinates = res.data.address_coordinates
      if (depoCoordinates.length > 0) {
        this.setState({
          lat: parseFloat(depoCoordinates[0]),
          lng: parseFloat(depoCoordinates[1]),
          zoom: 10
        })
      }
    })
  }

  fetchCustomers() {
    this.props.startLoading()
    return axios.get(this.customersUrl()).then((res) => {
      this.props.loadCustomers(res.data)
      this.props.stopLoading()
    })
  }

  fetchSelectedCustomerRoutes(geoJsonPoint) {
    const selectedCustomers = this.props.customers.filter((c) => c.selected)
    const { route } = this.props
    this.props.startLoading()
    axios
      .get(
        Routes.get_similar_routes_admin_customers_path(window.tenant, {
          format: 'json',
          selected_customers: selectedCustomers,
          route_id: route.id
        })
      )
      .then((res) => {
        this.setState({
          selectedCustomerRoutes: res.data,
          modalOpen: true,
          pointToMove: geoJsonPoint
        })
        this.props.stopLoading()
      })
      .catch((err) => this.props.stopLoading())
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { printPreview, mapPrinting, routeMinimumSalesEfficientCount } = props
    this.setState({ printPreview, mapPrinting, routeMinimumSalesEfficientCount }, () => this.renderGridPrinter())
    if (props.zoomedPoint && props.zoomedPoint !== this.state.zoomedPoint) {
      this.setState({ zoomedPoint: props.zoomedPoint }, () => {
        this.map.flyTo({
          center: [this.props.zoomedPoint.lon, this.props.zoomedPoint.lat],
          zoom: 15
        })
      })
    } else if (this.props.roadPoints.length === props.roadPoints.length && !this.state.draggable) {
      if (this.props.roadPoints.length > 0 && this.props.roadPoints[0].point) {
        const bounds = new mapboxgl.LngLatBounds(
          this.props.roadPoints[0].point.coordinates,
          this.props.roadPoints[0].point.coordinates
        )
        this.props.roadPoints.slice(1).forEach((coord) => {
          if (coord.point) {
            bounds.extend(coord.point.coordinates)
          }
        })

        if (this.map) {
          this.map.fitBounds(bounds, { padding: 70 })
        }
      }
    }
  }

  handleRenderMap = (map, e) => {
    this.map = map.target
  }

  routeChanged(oldRoadPoints, newRoadPoints) {
    const oldRoadPointsRouteId = [...new Set(oldRoadPoints.map((p) => p.route_id))]
    const newRoadPointsRouteId = [...new Set(newRoadPoints.map((a) => a.route_id))]
    return oldRoadPointsRouteId[0] !== newRoadPointsRouteId[0]
  }

  coordinatesChanged(oldRoadPoints, newRoadPoints) {
    const oldPropsCoordinates = map(oldRoadPoints, (e) => JSON.stringify(e.point)).toString()
    const newPropsCoordinates = map(newRoadPoints, (e) => JSON.stringify(e.point)).toString()
    return oldPropsCoordinates !== newPropsCoordinates
  }

  createPoint(coords, id) {
    const newRoutePoint = new RoutePoint()
    const point = {
      type: 'Point',
      coordinates: [coords.lng, coords.lat],
      properties: 1,
      address: '',
      _objectId: id,
      color: 'gray',
      opacity: 1,
      homePoint: false
    }

    newRoutePoint.point = point

    if (isIsbilen) {
      this.isbillencoordinatesToAddress(point, coords, this.createPointCallback.bind(this), newRoutePoint)
    } else {
      this.coordinatesToAddress(point, coords, this.createPointCallback.bind(this), newRoutePoint)
    }
  }

  createPointCallback(address, routePoint) {
    routePoint.address = address
    this.props.createRoutePointFromMap(routePoint)
  }

  updatePointCallback(address, routePoint) {
    const { addressChanged } = routePoint
    routePoint.address = address
    routePoint.addressChanged = addressChanged === undefined ? 0 : addressChanged + 1
    this.props.updatePoint(routePoint)
  }

  renderViewRenderingSwitchButton() {
    const { customerView, routeMinimumSalesEfficientCount } = this.state
    const icon = customerView ? 'people' : 'people_outline'

    if (routeMinimumSalesEfficientCount) {
      return (
        <div className='map-action-container map-switch'>
          <a className='map-layer-label' onClick={this.toggleViewRenderingSwitch.bind(this)}>
            <i className='material-icons'>{icon}</i>
          </a>
        </div>
      )
    }
  }

  renderDraggableToggle() {
    const { draggable } = this.state
    const dragText = draggable ? 'DRAG ON' : 'DRAG OFF'
    const color = draggable ? 'green' : 'unset'

    return (
      <Button
        variant='contained'
        onClick={this.toggleDragging.bind(this)}
        style={{ ...styles.editLocationButton, color: color }}
      >
        <i className='material-icons' style={{ fontSize: 16 }}>
          edit_location
        </i>
        <span style={{ lineHeight: '18px' }}>{dragText}</span>
      </Button>
    )
  }

  renderTooltipIcon() {
    const {
      route: { minimum_efficient_sales_count }
    } = this.props

    if (minimum_efficient_sales_count) {
      return (
        <div className='map-action-container map-tooltip'>
          <a className='map-layer-label' onClick={this.toggleTooltip.bind(this)}>
            <i className='material-icons'>info</i>
          </a>
        </div>
      )
    }
  }

  renderGridPrinter() {
    if (!this.map || !this.state.printPreview) return
    return <PrintMapWithGrid map={this.map} />
  }

  renderPrintingProgress() {
    if (this.state.mapPrinting != 'started') return
    const { gridItemsBounds } = this.state
    return (
      <div className='printingProccessingInfoWrapper'>
        <div className='printingProccessingInfoBlock'>
          <CircularProgress size={32} className='printingProccessingSpinner' />
          <span>Printing in progress, this can take up to few minutes!</span>
          <br />
          <br />
          <LinearProgress
            variant='determinate'
            value={(this.props.printedPages / this.props.totalPrintingPages) * 100}
          />
          <div style={{ textAlign: 'right', fontSize: 18 }}>
            <small>
              {this.props.printedPages} of {this.props.totalPrintingPages} pages printed.
            </small>
          </div>
        </div>
      </div>
    )
  }

  renderMapMarkersStyleToggle() {
    const { useNewMapMarkers } = this.state
    const text = 'FAST MAP'
    const color = useNewMapMarkers ? 'green' : 'unset'

    if (isIsbilen) {
      return (
        <Button
          vairant='contained'
          onClick={this.toggleMapMarkersStyle.bind(this)}
          style={{ ...styles.toggleMapMarkerButton, color: color }}
        >
          <i className='material-icons' style={{ fontSize: 16 }}>
            room
          </i>
          <span style={{ lineHeight: '18px' }}>{text}</span>
        </Button>
      )
    }
  }

  renderDraggableToggle() {
    const { draggable } = this.state
    const dragText = draggable ? 'DRAG ON' : 'DRAG OFF'
    const color = draggable ? 'green' : 'unset'

    if (this.props.route.id) {
      return (
        <Button
          variant='contained'
          onClick={this.toggleDragging.bind(this)}
          style={{ ...styles.editLocationButton, color: color }}
        >
          <i className='material-icons' style={{ fontSize: 16 }}>
            edit_location
          </i>
          <span style={{ lineHeight: '18px' }}>{dragText}</span>
        </Button>
      )
    }
  }

  toggleMapMarkersStyle() {
    const { useNewMapMarkers } = this.state
    this.setState({ useNewMapMarkers: !useNewMapMarkers })
  }

  toggleCustomerView() {
    this.setState({ customerView: !this.state.customerView })
  }

  toggleViewRenderingSwitch() {
    if (this.props.customers.length > 0) {
      this.toggleCustomerView()
    } else {
      this.fetchCustomers().then(this.toggleCustomerView.bind(this))
    }
  }

  toggleDragging() {
    const { draggable } = this.state
    this.setState({ draggable: !draggable })
  }

  toggleTooltip() {
    this.setState({ toggleTooltip: !this.state.toggleTooltip })
  }

  toggleCustomer(index) {
    const customers = this.props.customers.slice(0)
    customers[index].selected = !customers[index].selected
    this.props.startLoading()
    axios
      .get(
        Routes.get_phones_admin_customers_path(window.tenant, {
          selected_address: customers[index].address,
          route_id: this.props.route.id,
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ customers: res.data, phoneModalOpen: true })
        this.props.stopLoading()
      })
      .catch((err) => {
        this.props.stopLoading()
      })

    this.props.loadCustomers(customers)
  }

  openMovingModal(geoJsonPoint) {
    const selectedCustomers = this.props.customers.filter((c) => c.selected)
    if (selectedCustomers.length > 0) {
      this.fetchSelectedCustomerRoutes(geoJsonPoint)
    }
  }

  closeMovingModal() {
    this.setState({ modalOpen: false, pointToMove: {} })
  }

  cancelMove() {
    const { marker } = this.state
    const { coordinates } = this.state.currentPoint.point
    const position = { lat: coordinates[1], lng: coordinates[0] }
    marker.setLatLng(position, { draggable: 'true' })
  }

  closeDialogAndMove() {
    const { marker, currentPoint, point } = this.state
    const position = { lat: point.coordinates[1], lng: point.coordinates[0] }
    currentPoint.point = point
    marker.setLatLng(position, { draggable: 'true' })
    if (isIsbilen) {
      this.isbillencoordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint)
    } else {
      this.coordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint)
    }
  }

  customersUrl() {
    return window.Routes.customers_route_path(window.tenant, window.depoId, this.props.route.id, { format: 'json' })
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map((roadPoint) => roadPoint.point)
  }

  coordinatesToAddress(point, latLng, callback, routePoint = null, originaRoutePoint = null) {
    axios
      .get(
        Routes.api_hjemis_coordinates_path(window.tenant, {
          latitude: latLng.lat,
          longitude: latLng.lng
        })
      )
      .then((res) => {
        if (res.status == 200) {
          const address = res.data
          if (address != null) {
            callback(address, routePoint)
          } else {
            alert('No results found')
          }
        } else {
          if (originaRoutePoint) {
            callback(originaRoutePoint.address, originaRoutePoint)
          }
          alert('mapbox call failed with code ' + res.status)
        }
      })
  }

  isbillencoordinatesToAddress(point, latLng, callback, routePoint = null, originaRoutePoint = null) {
    axios
      .get(
        Routes.api_isbilen_coordinates_path(window.tenant, {
          X: latLng.lat,
          Y: latLng.lng
        })
      )
      .then((res) => {
        if (res.status == 200) {
          const address = res.data
          if (address != null) {
            callback(address, routePoint)
          } else {
            alert('No results found')
          }
        } else {
          if (originaRoutePoint) {
            callback(originaRoutePoint.address, originaRoutePoint)
          }
          alert('1881 call failed with code ' + res.status)
        }
      })
  }

  conditionalDrawControl() {
    if (this.props.route.id) {
      return <DrawControl createPoint={this.createPoint.bind(this)} />
    }
  }

  markerStateChange(currentPoint, marker, newpoint) {
    this.setState({ currentPoint: currentPoint, marker, newpoint })
  }

  render() {
    return (
      <div className='map-view-container'>
        <RouteMapMoveCustomersModal
          selectedCustomers={this.props.customers.filter((c) => c.selected)}
          state={this.state}
          props={this.props}
          closeMovingModal={this.closeMovingModal.bind(this)}
        />
        <div style={{ flex: 1.25 }} className='map-sidebar-wrapper'>
          {this.props.children}
        </div>
        <div className='map-container'>
          <MapboxMap
            onRender={this.handleRenderMap.bind(this)}
            createPoint={this.createPoint.bind(this)}
            accessToken={this.props.access_token}
            initialViewState={this.state.initialView}
          >
            <RouteMapCustomers
              customerView={this.state.customerView}
              customers={this.props.customers}
              roadPoints={this.props.roadPoints}
              toggleCustomer={this.toggleCustomer.bind(this)}
            />
            <RouteMapMarkers
              state={this.state}
              roadPoints={this.props.roadPoints}
              openMovingModal={this.openMovingModal.bind(this)}
              setState={this.markerStateChange.bind(this)}
              isbillencoordinatesToAddress={this.isbillencoordinatesToAddress.bind(this)}
              coordinatesToAddress={this.coordinatesToAddress.bind(this)}
              updatePointCallback={this.updatePointCallback.bind(this)}
            />
            <RouteMapPoints
              placedPointsOnMap={this.props.placedPointsOnMap}
              useNewMapMarkers={this.state.useNewMapMarkers}
            />
            <RouteMapUnplannedSales unplannedSales={this.props.unplannedSales} customerView={this.state.customerView} />
            {this.conditionalDrawControl()}
            {this.renderDraggableToggle()}
          </MapboxMap>

          <PointMovingConfirmationModal
            state={this.state}
            isbillencoordinatesToAddress={this.isbillencoordinatesToAddress.bind(this)}
            coordinatesToAddress={this.coordinatesToAddress.bind(this)}
            updatePointCallback={this.updatePointCallback.bind(this)}
          />
          <ConfirmationDialog
            open={this.state.dialogOpen}
            close={this.cancelMove.bind(this)}
            leave={this.closeDialogAndMove.bind(this)}
            confirmAndCloseText='Confirm'
            cancelText='Cancel'
            title='Custom address detected!'
            content='The point you are moving has a custom address, do you want to move it?'
          />
          <RouteMapTooltip state={this.state} toggleTooltip={this.toggleTooltip.bind(this)} />
          <Modal
            open={this.state.phoneModalOpen}
            close={() => this.setState({ phoneModalOpen: false })}
            title='Selected customer phones'
          >
            {this.state.customers.map((customer) => {
              return (
                <p key={customer.id}>
                  <a href={Routes.edit_admin_customers_path(window.tenant, customer.id)} target='_blank'>
                    {customer.phone}
                  </a>
                  {customer.other_route && ` - ${customer.other_route.name}`}
                </p>
              )
            })}
          </Modal>
          {this.renderMapMarkersStyleToggle()}
          {this.renderViewRenderingSwitchButton()}
          {this.renderTooltipIcon()}
          {this.renderGridPrinter()}
          {this.renderPrintingProgress()}

          <SimilarRoutesSelect />
        </div>
        <Flash />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roadPoints: state.roadPoints,
    routes: state.resources,
    orderTransactions: state.orderTransactions,
    carStops: state.carStops,
    route: state.route,
    carRoutes: state.carRoutes,
    planEvent: state.planEvent,
    zoomedPoint: state.zoomedPoint,
    placedPointsOnMap: state.placedPointsOnMap,
    printPreview: state.printPreview,
    mapPrinting: state.mapPrinting,
    printedPages: state.printedPages,
    totalPrintingPages: state.totalPrintingPages,
    unplannedSales: state.unplannedSales,
    customers: state.customers,
    routeMinimumSalesEfficientCount: state.routeMinimumSalesEfficientCount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    createRoutePointFromMap: (point) => dispatch(createRoutePointFromMap(point)),
    updatePoint: (point) => dispatch(updatePoint(point)),
    loadRoadPoints: (points) => dispatch(loadRoadPoints(points)),
    loadCustomers: (customers) => dispatch(loadCustomers(customers))
  }
}

const RouteMap = connect(mapStateToProps, mapDispatchToProps)(RouteMapClass)

export default RouteMap
