/**
 * File generated by js-routes 2.2.5
 * Based on Rails 6.0.6.1 routes of FunRoutePlanner::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (true &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/active_customers_in_point(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_customers_in_point_route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"active_customers_in_point"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id/active_products(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_products_inventory_product_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"active_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/active_routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_routes_route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[6,"active_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/add_b2b_customer(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_b2b_customer_route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_b2b_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_b2b_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_b2b_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_orders(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_b2b_orders_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_car_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_cars_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_depo_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_depos_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_campaigns/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sms_campaign_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_campaigns(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sms_campaigns_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_templates/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sms_template_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_templates(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sms_templates_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/stop_types/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_stop_type_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stop_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/stop_types(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_stop_types_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stop_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/users/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/user_http_requests(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_http_requests_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_http_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/users(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/wallmob(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_wallmob_index_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wallmob"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/all_customers(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_customers_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"all_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos/all_depos(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_depos_admin_depos_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[2,[7,"/"],[2,[6,"all_depos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/actual_plan_events/all_events(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_events_api_eshop_v1_actual_plan_events_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"actual_plan_events"],[2,[7,"/"],[2,[6,"all_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/alternative_active_routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alternative_active_routes_route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"alternative_active_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api-address-docs(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_address_docs_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api-address-docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/address_search(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_address_search_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"address_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/address_search_driving(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_address_search_driving_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"address_search_driving"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/actual_plan_events(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_actual_plan_events_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"actual_plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/b2b_orders(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_b2b_orders_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"b2b_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/create_voyado_customer(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_create_voyado_customer_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"create_voyado_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/customer_visits(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_customer_visits_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"customer_visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_customers_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/eshop/orders(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eshop_v1_orders_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/hjemis_app/depo_locations(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hjemis_app_depo_locations_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hjemis_app"],[2,[7,"/"],[2,[6,"depo_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/hjemis_app/nearby_trucks(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hjemis_app_nearby_trucks_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hjemis_app"],[2,[7,"/"],[2,[6,"nearby_trucks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/hjemis_app/nearby_upcoming_points(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hjemis_app_nearby_upcoming_points_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hjemis_app"],[2,[7,"/"],[2,[6,"nearby_upcoming_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/hjemis_app/set_favorite_point(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hjemis_app_set_favorite_point_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hjemis_app"],[2,[7,"/"],[2,[6,"set_favorite_point"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/hjemis_coordinates(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hjemis_coordinates_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hjemis_coordinates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/isbilen_coordinates(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_isbilen_coordinates_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"isbilen_coordinates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/isbillen_addresss(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_isbillen_addresss_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"isbillen_addresss"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/lakrids/v1/plan_events(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lakrids_v1_plan_events_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lakrids"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/leadfamly/customer(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leadfamly_customer_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leadfamly"],[2,[7,"/"],[2,[6,"customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/public/customers/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_public_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/auth(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_auth_index_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/drivers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_drivers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"drivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/depo/:depo_id/plan_events/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/wallmob_raw_orders(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_wallmob_raw_orders_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"wallmob_raw_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/depos(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_depos_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"depos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_plan_event_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/:plan_event_id/plan_event_route_points/:id(.:format)
 * @param {any} tenant
 * @param {any} plan_event_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_plan_event_plan_event_route_point_path = __jsr.r({"tenant":{"r":true},"plan_event_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"plan_event_id"],[2,[7,"/"],[2,[6,"plan_event_route_points"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_plan_events_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/todays_leaderboard(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_todays_leaderboard_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"todays_leaderboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v3/plan_events/:plan_event_id/plan_event_route_points/:id(.:format)
 * @param {any} tenant
 * @param {any} plan_event_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v3_plan_event_plan_event_route_point_path = __jsr.r({"tenant":{"r":true},"plan_event_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"plan_event_id"],[2,[7,"/"],[2,[6,"plan_event_route_points"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v3/wallmob/customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v3_wallmob_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v3/wallmob/products(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v3_wallmob_products_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/:id/approve_document(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_document_inventory_warehouse_manual_counting_document_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id/approve_unmapped_end_of_day_report(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_unmapped_end_of_day_report_end_of_day_report_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_unmapped_end_of_day_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/available_warehouse_owners(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_warehouse_owners_inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[6,"available_warehouse_owners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/:id/b2b_orders(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const b2b_orders_api_v2_plan_event_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"b2b_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/:id/b2c_orders(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const b2c_orders_api_v2_plan_event_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"b2c_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/depo/:depo_id/plan_events/:id/car_routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const car_routes_api_v1_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"car_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/car_routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const car_routes_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"car_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/depo/:depo_id/plan_events/:id/car_stops(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const car_stops_api_v1_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"car_stops"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/car_stops(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const car_stops_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"car_stops"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/car_stops_with_route_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const car_stops_with_route_points_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"car_stops_with_route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/cash_registers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cash_registers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"cash_registers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/cleanup(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleanup_eshop_b2b_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[6,"cleanup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/cleanup(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleanup_eshop_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cleanup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/convert_to_route(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_to_route_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"convert_to_route"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/copy_and_reverse_route(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_and_reverse_route_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_and_reverse_route"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/copy_route(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_route_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_route"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/public/customers/create_sms_isbilen(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_sms_isbilen_api_public_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"create_sms_isbilen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/drivers/current(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const current_api_v1_drivers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[6,"current"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/customer_support/plan_event_route_points(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_support_plan_event_route_points_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"customer_support"],[2,[7,"/"],[2,[6,"plan_event_route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/wallmob/customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_admin_wallmob_index_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/customers(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/dashboard(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_index_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/user_http_requests/:id/data(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_admin_user_http_requests_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_http_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/destroy_by_address(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_by_address_route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[6,"destroy_by_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/destroy_multiple(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_multiple_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"destroy_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/sign_out(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/destroy_with_series(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_with_series_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_with_series"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/drivers(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const drivers_feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[6,"drivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_b2b_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_cars_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_customers_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_depos_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_campaigns/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_sms_campaigns_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_templates/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_sms_templates_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/stop_types/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_stop_types_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stop_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/users/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_users_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v2_plan_event_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_products_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_warehouse_manual_counting_documents_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_orders/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_warehouse_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_warehouse_stock_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_warehouse_stock_transfers_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_event_templates/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_plan_event_templates_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_event_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/edit(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_routes_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/password/edit(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_of_day_report_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eshop_b2b_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eshop_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers/export(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_admin_b2b_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/:id/export_manual_counting_document(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_manual_counting_document_inventory_warehouse_manual_counting_document_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_manual_counting_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders/:id/export_order_document(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_order_document_inventory_warehouse_stock_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_order_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_orders/export_to_csv(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_to_csv_admin_b2b_orders_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[6,"export_to_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers/:id/export_transfer_document(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_transfer_document_inventory_warehouse_stock_transfer_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_transfer_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedback_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id/fetch_delivery_time(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_delivery_time_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_delivery_time"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id/fetch_delivery_time(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_delivery_time_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_delivery_time"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers/fetch_status_sizes(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_status_sizes_admin_b2b_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[2,[7,"/"],[2,[6,"fetch_status_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars/serializer/:serializer(/filter/:filter)(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} serializer
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_admin_cars_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"serializer":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[6,"serializer"],[2,[7,"/"],[2,[3,"serializer"],[2,[1,[2,[7,"/"],[2,[6,"filter"],[2,[7,"/"],[3,"filter"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/serializer/:serializer(/filter/:filter)(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} serializer
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"serializer":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"serializer"],[2,[7,"/"],[2,[3,"serializer"],[2,[1,[2,[7,"/"],[2,[6,"filter"],[2,[7,"/"],[3,"filter"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/filter/:filter(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} filter
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_routes_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"filter":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"filter"],[2,[7,"/"],[2,[3,"filter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/get_phones(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_phones_admin_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"get_phones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:id/get_products_balance(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_products_balance_inventory_warehouse_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_products_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/get_similar_routes(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_similar_routes_admin_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"get_similar_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/google_estimate_update(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_estimate_update_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"google_estimate_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/has_eshop_stops(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const has_eshop_stops_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"has_eshop_stops"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /health_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health_check"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/heat_map(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heat_map_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"heat_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/heat_map_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heat_map_points_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"heat_map_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/historical_plan_events(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const historical_plan_events_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"historical_plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/history(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_product_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_products_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_manual_counting_document_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_manual_counting_documents_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_orders(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_product_balances/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_product_balance_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_product_balances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_stock_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_stock_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_stock_transfer_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouse_stock_transfers_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/isbillen_addresses(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const isbillen_addresses_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"isbillen_addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id/move_remaining_product_balances(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_remaining_product_balances_inventory_product_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_remaining_product_balances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/move_route_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_route_points_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id/move_to_different_depo(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_to_different_depo_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_different_depo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id/move_to_different_depo(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_to_different_depo_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_different_depo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/b2b_customers/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_b2b_customer_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"b2b_customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_cars_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_depos_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_campaigns/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_sms_campaigns_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_templates/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_sms_templates_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/stop_types/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_stop_types_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stop_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/users/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_users_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v2_plan_event_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_products_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_warehouse_manual_counting_documents_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_orders/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_warehouse_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_warehouse_stock_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_warehouse_stock_transfers_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_event_templates/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_plan_event_templates_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_event_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/new(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_routes_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/password/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/sign_in(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/next_events(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_events_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"next_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/lakrids/v1/customers/next_visits(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_visits_api_lakrids_v1_customers_path = __jsr.r({"tenant":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lakrids"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"next_visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id/order_line_items(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_line_items_end_of_day_report_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"order_line_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id/orders(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_end_of_day_report_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/owners_list(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owners_list_inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[6,"owners_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:locale/c/:uuid/pu(.:format)
 * @param {any} locale
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const partial_unsubscribe_public_customer_path = __jsr.r({"locale":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"c"],[2,[7,"/"],[2,[3,"uuid"],[2,[7,"/"],[2,[6,"pu"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:plan_event_id/order_transactions(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} plan_event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_order_transactions_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"plan_event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"plan_event_id"],[2,[7,"/"],[2,[6,"order_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/dashboard/plan_event_routes_with_quota(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_routes_with_quota_dashboard_index_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"plan_event_routes_with_quota"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/dashboard/plan_event_routes_without_quota(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_routes_without_quota_dashboard_index_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"plan_event_routes_without_quota"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_event_templates/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_template_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_event_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_event_templates(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_event_templates_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_event_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/possible_plan_events(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const possible_plan_events_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[6,"possible_plan_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/product_transfer_exports(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_transfer_exports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{"d":"text"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"product_transfer_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/wallmob/products(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_admin_wallmob_index_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /authorize_customer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_authorize_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"authorize_customer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:locale/c/:uuid(.:format)
 * @param {any} locale
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_customer_path = __jsr.r({"locale":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"c"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:locale/c(.:format)
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_customers_path = __jsr.r({"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"c"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:locale/feedbacks(.:format)
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_feedbacks_path = __jsr.r({"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /isbillen_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_isbillen_addresses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"isbillen_addresses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:locale/cars/:id/perform_latest_location_update(.:format)
 * @param {any} locale
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_perform_car_latest_location_update_path = __jsr.r({"locale":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"perform_latest_location_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:locale/:uuid(.:format)
 * @param {any} locale
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_plan_event_route_point_path = __jsr.r({"locale":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plan_event_route_point_subscribers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_plan_event_route_point_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan_event_route_point_subscribers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:locale(.:format)
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_plan_event_route_points_path = __jsr.r({"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscribe_for_delivery_truck(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_subscribe_for_delivery_truck_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscribe_for_delivery_truck"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscribed_customer_delivery_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_subscribed_customer_delivery_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscribed_customer_delivery_info"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id/remaining_warehouse_product_balances(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remaining_warehouse_product_balances_inventory_product_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remaining_warehouse_product_balances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/remove_b2b_customer(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_b2b_customer_route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_b2b_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/depo/:depo_id/plan_events/:id/route_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_points_api_v1_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/route_points(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_points_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"route_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const routes_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const routes_feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/sales_exports(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sales_exports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{"d":"text"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"sales_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/search_by_address(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_by_address_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"search_by_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/send_stop_sms(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_stop_sms_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_stop_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/cars/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_cars_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"cars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_customers_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depos/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_depos_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_campaigns/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_sms_campaigns_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/sms_templates/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_sms_templates_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/stop_types/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_stop_types_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stop_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/user_http_requests/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_user_http_requests_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_http_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/depo/:depo_id/users/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_admin_users_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/feedbacks/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_feedbacks_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"feedbacks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/products/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_products_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:id/:filter(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {any} filter
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouse_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"filter":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"filter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/manual_counting_documents/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouse_manual_counting_documents_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"manual_counting_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouse_orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouse_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouse_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_orders/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouse_stock_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:warehouse_id/stock_transfers/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} warehouse_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouse_stock_transfers_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"warehouse_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"warehouse_id"],[2,[7,"/"],[2,[6,"stock_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/inventory/depo/:depo_id/warehouses/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_inventory_warehouses_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"inventory"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_event_templates/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_plan_event_templates_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_event_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_plan_events_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_route_points_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_routes_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/sidekiq
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({"tenant":{"r":true}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[6,"sidekiq"]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/similar_routes(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const similar_routes_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"similar_routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/small_route_points_data(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const small_route_points_data_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"small_route_points_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/sms_customer_map(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_customer_map_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sms_customer_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/sms_customers/full_details(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_customers_full_details_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sms_customers"],[2,[7,"/"],[2,[6,"full_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/sms_customers(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_customers_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sms_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/:id/sms_receivers(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_receivers_admin_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sms_receivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/dashboard/sms_stops_made(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_stops_made_dashboard_index_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"sms_stops_made"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/dashboard/sms_stops_not_made(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_stops_not_made_dashboard_index_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"sms_stops_not_made"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/plan_events/:id/stop_sending_sms(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_sending_sms_plan_event_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop_sending_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_root_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/plan_events/todays_events(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const todays_events_api_v2_plan_events_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"plan_events"],[2,[7,"/"],[2,[6,"todays_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/undo_delete(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undo_delete_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"undo_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/end_of_day_reports/unmapped_end_of_day_reports(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unmapped_end_of_day_reports_end_of_day_reports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"end_of_day_reports"],[2,[7,"/"],[2,[6,"unmapped_end_of_day_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/routes/:id/unplanned_sales(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unplanned_sales_route_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unplanned_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:locale/c/:uuid/u(.:format)
 * @param {any} locale
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsubscribe_public_customer_path = __jsr.r({"locale":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[2,[7,"/"],[2,[6,"c"],[2,[7,"/"],[2,[3,"uuid"],[2,[7,"/"],[2,[6,"u"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/orders/:id/update_b2b_order(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_b2b_order_api_v2_order_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_b2b_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/api/v2/orders/:id/update_b2c_order(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_b2c_order_api_v2_order_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_b2c_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/admin/customers/update_multiple(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_multiple_admin_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/route/:route_id/route_points/:id/update_position(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_position_route_point_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"route"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"route_points"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_position"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/update_printed_status(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_printed_status_eshop_b2b_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[6,"update_printed_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/update_printed_status(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_printed_status_eshop_orders_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"update_printed_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id/update_route(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_route_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_route"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id/update_route(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_route_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_route"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/b2b_orders/:id/update_status(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_eshop_b2b_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"b2b_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/eshop/depo/:depo_id/orders/:id/update_status(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_eshop_order_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"eshop"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/password(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/users/sign_in(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/version(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const version_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"version"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/wallmob/customers/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallmob_customer_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/wallmob/customers(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallmob_customers_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/wallmob/products/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallmob_product_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/wallmob/products(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallmob_products_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/wallmob/users(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallmob_users_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"wallmob"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:tenant/depo/:depo_id/writeoff_exports(.:format)
 * @param {any} tenant
 * @param {any} depo_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const writeoff_exports_path = __jsr.r({"tenant":{"r":true},"depo_id":{"r":true},"format":{"d":"text"}}, [2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"depo"],[2,[7,"/"],[2,[3,"depo_id"],[2,[7,"/"],[2,[6,"writeoff_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);


